<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :items="items"
    item-text="text"
    item-value="value"
  >
  </v-select>
</template>

<script>
  import year from "./year";

  export default {
    inheritAttrs: false,
    name: "YearsPicker",
    props: {
      value: { type: [String, Array, Number], required: false }
    },
    data: () => ({
      items: year,
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    },
    mounted() {
      this.value = new Date().getFullYear();
    }
  };
</script>

<style scoped></style>
